/* darkest_blue    #16202d */
/* background_blue #1b2838 */

html {
  background-color: #001529;
}

.title-search {
  min-height: 50px;
}

.btn-container {
  display: flex;
  justify-content: center;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.row {
  max-width: 100vw;
}

.background > h1 {
  color: #fff;
  text-align: center;
  padding-top: 0.3em;
  padding-bottom: 0.3em;
}

.game-title {
  padding-left: 1em !important;
}

div.game-title > a {
  color: #fff;
}

.game-price {
  text-align: right;
}


.background {
  background-color: #1b2838;
  min-height: 84.5vh;
}

.game {
  background-color: #16202d;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  margin-bottom: 0.4em;
  padding-left: 0.3em;
}

.plus {
  font-size: 1.3em;
}

.loginButton {
  width: 100%;
}

.on_sale {
  color: #90b90c;
}

.on_sale > div {
  margin-bottom: 0.5em;
}

.ant-layout-header {
  padding: 0px !important;
}

.error {
  color: #f00;
  text-align: center;
}

.modal-error {
  font-size: 0.88em;
  vertical-align: bottom;
  margin-bottom: 0px;
}

.ant-form-item-required {
  color: #fff !important;
}

.form-redirect {
  color: #fff;
  text-align: center;
}

.dropdown-form {
  display: flex;
  align-items: center;
}

.label {
  color: #fff;
  font-weight: 700;
  width: 7vw;
}

.operator-dropdown {
  width: 7vw;
}

.search-input {
  width: 22vw;
  height: 4.5vh;
  border-radius: 5px;
  padding-left: 0.5vw;
}

.title-input {
  width: 30vw;
  height: 4.5vh;
  border-radius: 5px;
  padding-left: 0.5vw;
}

.dropdown {
  width: 22vw;
}

.dropdown-lg {
  width: 30vw;
}

.text {
  color: #fff;
}

@media all and (min-width: 1000px) {
  .text {
    font-size: 0.9em;
    margin-top: 0.5em;
  
    color: #fff;
    text-align: top;
  }
}

@media all and (max-width: 999px) {
  .text {
    font-size: 0.7em;
  
    color: #fff;
    text-align: top; 
  } 
}

.fa-check-circle {
  vertical-align: middle;
}

.fa-cog {
  text-align: center;
}


@media all and (min-width: 420px) {
  .ant-menu-item {
    font-size: 1em;
    padding: 0 1.2em !important;
  }
}

@media all and (min-width: 362px) and (max-width: 419px) {
  .ant-menu-item {
    font-size: 1em;
    padding: 0 3vw !important;
  }
}

@media all and (max-width: 360px) {
  .ant-menu-item {
    font-size: 1em;
    padding: 0 0.7em !important;
  }
}


.searchbar {
  padding-bottom: 1em;
}


@media all and (max-width: 500px) {
  .divider {
    background-color: #1b2838;
    height: 5em;
  }
}

@media all and (min-width: 501px) {
  .divider {
    background-color: #1b2838;
    height: 0px;
  }
}

.btn-pad {
  margin-left: 0.8em;
  margin-right: 0em;
}

@media all and (min-width: 400px) {
  .btn {
    padding: .375rem .6rem !important;
  }
}

.game-img {
  padding-left:  0.5em;
  /* height: 55px; */
  /* height: 8.5vh; */
  width: 11vw;
  /* width:  149px; */
}

@media all and (min-width: 768px) and (max-width: 1199px) {
  #price2 {
    padding-right: 25px;
  }
}

@media all and (max-width: 474px) {
  #price2 {
    padding-left: 35px;
  }
}

@media all and (min-width: 475px) and (max-width: 575px) {
  #price1 {
    padding-left: 40px;
  }

  #price2 {
    margin-left: 20px;
  }
}

@media all and (max-width: 474px) {
  .collection-price1 {
    padding-left: 23px !important;
  }

  .collection-price2 {
    margin-left: 15px !important;
  }
}


@media all and (max-width: 575px) {
  #wishlist div.row {
    margin-right: -10px;
    margin-left: -10px;
  }

  #wishlist div.row div.game-title {
    padding-right: 0px;
  }

  #wishlist div.row div.game-price {
    padding-right: 8px;
  }
}


@media all and (max-width: 575px) {
  #collection div.row {
    margin-right: -10px;
    margin-left: -10px;
  }

  #collection div.row div.game-title {
    padding-right: 0px;
  }

  #collection div.row div.game-price {
    padding-right: 8px;
  }
}


/* div.container button.ant-input-search-button {
  background-color: #16202d; 
  border-color: #1b2838;
  background-color: #1b2838;
  border-color: #16202d;
  border: 2px solid #16202d;
} */
/* .anticon-search svg  */
/* div.container span.ant-input-group-addon button path */

.login-prompt {
  text-align: center;
}

#login-prompt .btn a {
  color: #fff;
  margin-right: 0px !important;
}

/* Both of the below are necessary for 100% login-modal buttons */
#login-prompt .btn { width: 100%; }
#login-prompt a    { width: 100% }


@media all and (max-width: 575px) {
  .ant-form-item-label {
    flex: 0 0 20% !important;
    right: 0px;
  }

  .ant-form-item-control {
    flex: 0 0 80% !important;
  }

  .submit-btn .ant-form-item-control {
    padding-left: 10%;
    padding-right: 10%;
    flex: 0 0 100% !important;
  }

  .ant-form-item {
    padding-left: 5% !important;
    padding-right: 5% !important;
    flex: 0 0 100% !important;
  }

  label {
    float: right;
    padding-right: 0.5em;
  }
}

/* .form-bkg {
  background-color: #16202d;
}
.ant-form {
  padding-top: 1.3em !important;
} */

/* div.ReactVirtualized__Grid__innerScrollContainer div {
} */


.table-header {
  vertical-align: bottom;
  float: bottom;
}

@media all and (min-width: 421px){
  .title-header {
    margin-left: 199px;
  }

  .collection-header {
    margin-left: 150px;
  }
}

@media all and (max-width: 475px) {
  .cog {
    padding-left: 7px !important;
  }
}

@media all and (max-width: 420px) {

  /* .curr-header {
    margin-left: -10px !important;
  }

  .base-header {
    margin-left: 20px;
  } */

  .title-header {
    margin-left: 170px;
  }

  .collection-header {
    margin-left: 131px;
  }

  div.row div#gamelist {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }

  .btn {
    padding: .375rem .4rem !important;
  }

  .game-img {
    padding-left: 0.3em;
    width: 130px;
  }

  .game-title {
    padding-right: 5px !important;
  }

  #price1 {
    padding-left: 5px;
  }

  #price2 {
    padding-left: 20px;
  }

  .fa-check-circle {
    font-size: 1.2em;
  }
}

@media all and (max-width: 410px) {
  .ant-form-item-label > label {
    font-size: 12px !important;
  }
}

@media all and (max-width: 361px) {
  .ant-form-item-label > label {
    font-size: 10px;
  }
} 

div.blank-search {
  color: #fff;
  justify-content: center;
}

.ant-layout-footer {
  background-color: #001529 !important;
  color: #67737f !important;
  /* padding: 0.5em 2em !important; */
  padding-left: 2em !important;
  padding-right: 2em !important;
  padding-top: 1.5vh !important; 
  padding-bottom: 3.2vh !important;
}